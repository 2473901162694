import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import headerImage from '../assets/header.png'; // Adjust the path based on your folder structure

const Landing = () => {
  const navigate = useNavigate();

  // State for date, month, and year
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  // Check local storage for isLogin flag
  useEffect(() => {
    const isLogin = localStorage.getItem('isLogin');
    if (isLogin === 'true') {
      navigate('/finish'); // Redirect to Finish.js if logged in
    }
  }, [navigate]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    navigate('/telegram'); // Navigate to Telegram.js on form submission
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navbar */}
      <nav className="bg-cyan-600 p-4">
        <div className="container mx-auto">
          <h1 className="text-white text-2xl font-bold">Pendaftaran Kerja</h1>
        </div>
      </nav>

      {/* Header Image */}
      <div className="w-full">
        <img
          src={headerImage}
          alt="Header"
          className="w-full h-auto max-h-[500px] object-cover"
        />
      </div>

      {/* Registration Form */}
      <div className="container mx-auto p-6">
        <h2 className="text-3xl font-semibold mb-4">Formulir Pendaftaran</h2>
        <p className="mb-6">Silakan isi formulir di bawah ini untuk mendaftar.</p>

        <form
          onSubmit={handleFormSubmit}
          className="space-y-4 bg-white p-6 rounded shadow-md"
        >
          {/* Nama Lengkap */}
          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="nama"
            >
              Nama Lengkap (Sesuai E-KTP)
            </label>
            <input
              type="text"
              id="nama"
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          {/* Tanggal Lahir - Day, Month, Year Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Tanggal Lahir
            </label>
            <div className="flex space-x-2">
              {/* Day Selection */}
              <select
                value={day}
                onChange={(e) => setDay(e.target.value)}
                required
                className="w-1/3 p-2 border border-gray-300 rounded"
              >
                <option value="">Hari</option>
                {Array.from({ length: 31 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>

              {/* Month Selection */}
              <select
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                required
                className="w-1/3 p-2 border border-gray-300 rounded"
              >
                <option value="">Bulan</option>
                {[
                  'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
                  'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember',
                ].map((month, index) => (
                  <option key={index + 1} value={index + 1}>
                    {month}
                  </option>
                ))}
              </select>

              {/* Year Selection */}
              <select
                value={year}
                onChange={(e) => setYear(e.target.value)}
                required
                className="w-1/3 p-2 border border-gray-300 rounded"
              >
                <option value="">Tahun</option>
                {Array.from({ length: 100 }, (_, i) => (
                  <option key={i} value={new Date().getFullYear() - i}>
                    {new Date().getFullYear() - i}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Jenis Kelamin */}
          <div>
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="gender"
            >
              Jenis Kelamin
            </label>
            <select
              id="gender"
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="">Pilih Jenis Kelamin</option>
              <option value="male">Laki-Laki</option>
              <option value="female">Perempuan</option>
            </select>
          </div>

          {/* Agreement */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="agreement"
              className="h-4 w-4 text-cyan-600 border-gray-300 rounded"
              required
            />
            <label
              htmlFor="agreement"
              className="ml-2 block text-sm text-gray-700"
            >
              Saya Setuju dengan semua syarat & ketentuan
            </label>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-cyan-600 text-white p-2 rounded hover:bg-cyan-700"
          >
            Daftar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Landing;
